import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = ['form', 'input','input2']

	connect() {
		//console.log("started");	 
	}
	onPostSuccess (event) {
		console.log('success!');
	}

    update() {
		console.log("UPDATE");
		Rails.fire(this.formTarget, 'submit');
		// prevent default form submission
		event.preventDefault();
	}

	highlight(event) {
		// select everything in the input
		console.log("Selected!")
		if (this.hasInputTarget) {
			console.log(this.inputTarget);
			this.inputTarget.select();
		}
		// if input2 not null
		if (this.hasInput2Target) {
			console.log(this.input2Target);
		this.input2Target.select();
		}
		//event.stopImmediatePropagation();
	}
}
