import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"
export default class extends Controller {
    //static values = { seconds: Number };
    static targets = ["pulldown","searchinput"];

    connect() {
   
    }

    search(event) {
        console.log(event.target.value.toLowerCase())
        const query = event.target.value.toLowerCase()
        fetch("/search_exercise_types?q=" + query)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                var html = "";
                data.forEach(x => {
                    html = html + "<option value='" + x["id"] + "'>" + x["name"] + "</option>";
                });
                console.log(this.pulldownTarget.innerHTML)
                this.pulldownTarget.innerHTML = html;
            });
            
    }
    
    
}
