/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
//import '@hotwired/turbo-rails';
import { Turbo } from '@hotwired/turbo-rails';
import { } from 'peity-vanilla-rails';

//import * as Turbo from '@hotwired/turbo';

Turbo.session.drive = true;

document.addEventListener('turbo:load', function () {
	console.log('turbo:load event 1');
});
document.addEventListener('turbo:before-visit', function () {
	console.log('turbo:before-visit event');
});
document.addEventListener('turbo:before-fetch-request', function () {
	console.log('turbo:before-fetch-request event');
});
document.addEventListener('turbo:before-render', function () {
	console.log('turbo:before-render event');
});


require("css/application.scss")
import * as ActionCable from '@rails/actioncable'
require("channels")
require("../src/notifications")
require("../src/cable")
require('typeface-inter')
require("jquery")
//import { cable } from "@hotwired/turbo-rails"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
import 'alpinejs'
import ApexCharts from 'apexcharts'
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

Rails.start()
ActiveStorage.start()

ActionCable.logger.enabled = true
window.ApexCharts = ApexCharts;

const application = Application.start()
const context = require.context("controllers", true, /.js$/);
require.context('images', true)
application.load(definitionsFromContext(context))


function addEvent(element, evnt, funct) {
    if (typeof element !== "undefined" && element !== null) {
        if (element.attachEvent)
            return element.attachEvent('on' + evnt, funct);
        else
            return element.addEventListener(evnt, funct, false);
    }
}
  

window.addEventListener('load', function () {
    addEvent(
        document.getElementById('copypaste_public_dns_key'),
        'click',
        function () {
            copypaste("public_dns_key");
        }
    );
    addEvent(
        document.getElementById('copypaste_private_key'),
        'click',
        function () {
            copypaste("private_key");
        }
    );
});



let copypaste = function (textareaid) {
    console.log(textareaid)
    const textarea = document.getElementById(textareaid);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    window.showSuccess ("Copied");

};



document.addEventListener("turbo:load", () => {
    flatpickr(".flatpickr", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
    });
})
  
//import "controllers"
