import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["message","unansweredquestion"];
 
    initialize() {
        console.log("scroll_to_unanswered_controller initialized")
        if (this.unansweredquestionTargets.length > 0) {
            this.unansweredquestionTargets[0].scrollIntoView({ behavior: 'smooth' });
        } else if (this.messageTarget) {
            this.messageTarget.scrollIntoView({ behavior: 'smooth' });
        }
    }
    connect() {
        console.log("scroll_to_unanswered_controller connected")
        // if there are unanswered questions, scroll to the first one, otherwise scroll to the message
        if (this.unansweredquestionTargets.length > 0) {
            this.unansweredquestionTargets[0].scrollIntoView({ behavior: 'smooth' });
        } else if (this.messageTarget) {
            this.messageTarget.scrollIntoView({ behavior: 'smooth' });
        }
    }
    
}
