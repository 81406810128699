import { Controller } from "stimulus"

export default class extends Controller {
    static classes = [ "hide"]
    static targets = [ "output" ]

    connect() {
   
    }

    showthis(event) {
    }
    bla(){
        console.log(event.params.id)
        // console.log(this.outputTargets)
        // this.outputTargets.forEach(element => {
        //     element.classList.add(this.hideClass)
        // }
        // )
        //console.log(this.outputTarget.classList)
        //console.log(this.hideClass);
        console.log("COMMENTSHOW")
        if (this.outputTarget.classList.contains(this.hideclass))
        {
            console.log("currently hidden")
            this.outputTarget.classList.add(this.hideClass);

        }
        else
        {
            // Remove hidden class, thus show
            console.log('currently visible');
            this.outputTarget.classList.remove(this.hideClass);
            
        }
        console.log(this.outputTarget.classList);
    }
}
