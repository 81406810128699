import { Controller } from "stimulus"

export default class extends Controller {
    static values = { seconds: Number };
    static targets = ["output"];
    static classes = [ "counting","stopped","reset"]

    downloadTimer = null;
    timeleft = 0;
    stopped = false;
    connect() {
   
    }
    starttimer() {
        let that = this
        this.downloadTimer = setInterval(function () {
            if (that.timeleft <= 0) {
                clearInterval(that.downloadTimer);
                if (that.hasOutputTarget) {
                    that.outputTarget.innerHTML = "0";
                }
                    that.element.classList.remove(that.stoppedClass)
                    that.element.classList.add(that.resetClass)
                    that.element.classList.remove(that.countingClass)
                    that.element.classList.remove("animate-pulse")

            } else {
                //console.log(timeleft);
                if (that.hasOutputTarget) {
                    that.outputTarget.innerHTML = that.timeleft;
                }
                else {
                   // console.log("No target")
                }
            }
            that.timeleft -= 1;
        }, 1000);
    }

    start(event) {
        //console.log("-------------------")
        //console.log(this.downloadTimer);
        if (this.downloadTimer !== null) {
            if (this.stopped === false) {
                this.stopped = true;
                clearInterval(this.downloadTimer);
                if (this.timeleft <= 0)
                {
                    this.timeleft = this.secondsValue;
                    this.element.classList.remove(this.stoppedClass)
                    this.element.classList.add(this.resetClass)
                    this.element.classList.remove(this.countingClass)
                    this.element.classList.remove("animate-pulse")

                }
                else
                {
                    this.element.classList.add(this.stoppedClass)
                    this.element.classList.remove(this.resetClass)
                    this.element.classList.remove(this.countingClass)
                    this.element.classList.remove("animate-pulse")

                }
            }
            else
            {
                if (this.timeleft <= 0) {
                    this.timeleft = this.secondsValue;
                    this.element.classList.remove(this.stoppedClass)
                    this.element.classList.add(this.resetClass)
                    this.element.classList.remove(this.countingClass)
                    this.element.classList.remove("animate-pulse")

                }
                else {
                    this.element.classList.remove(this.stoppedClass)
                    this.element.classList.remove(this.resetClass)
                    this.element.classList.add(this.countingClass)
                    this.element.classList.add("animate-pulse")
                    this.starttimer();
                }
            }
        }
        else {
            this.timeleft = this.secondsValue;
            this.starttimer();
            this.element.classList.remove(this.stoppedClass)
            this.element.classList.remove(this.resetClass)
            this.element.classList.add(this.countingClass)
                    this.element.classList.add("animate-pulse")

        }
    };
    
}
