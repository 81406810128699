import { Controller } from 'stimulus'

export default class extends Controller {
  static classes = ['hide']
  static targets = ['output']

  connect () {
  }

  hidenall(event) {
      console.log(event)
      console.log("START")
    this.outputTargets.forEach(element => {
        console.log(element)
        console.log(event.params);
        console.log(element.id, event.params.id)
        if (element.id != event.params.id) {
          console.log('X');
          element.classList.add(this.hideClass);
        }
        else {
          if (element.classList.contains(this.hideClass)) {
            console.log('A');
            console.log(element.id);

            element.classList.remove(this.hideClass);
          }
          else {
            console.log('B');
            console.log(element.id);
            element.classList.add(this.hideClass);
          }
        }
      })
      event.preventDefault();
    }
}
